import { message } from "antd";
import { urlPrepare } from "helpers";
import { SplitApiV2 } from "redux/apiv2";
import Swal from "sweetalert2";

export const InvestmentsApi = SplitApiV2.injectEndpoints({
  endpoints: (builder) => ({
    getInvestments: builder.query<any, any>({
      query: ({ pageNumber, pageSize, byStatus }) =>
        urlPrepare(`/investments?page_number=${pageNumber}&page_size=${pageSize}`),
      providesTags: ["Investments"]
    }),
    getInvestmentsByClientId: builder.query<any, any>({
      query: ({ pageNumber, pageSize, clientId, investmentType, status }) => {
        let query = new URLSearchParams({
          page_number: pageNumber,
          page_size: pageSize,
          investment_type: investmentType
        });
        if (status && status !== 'ALL') {
          query.append('status', status);
        }

        return clientId
          ? urlPrepare(`/users/${clientId}/investments?${query}`)
          : urlPrepare(`/users/investments?${query}`);
      },
      providesTags: ["Investments"]
    }),
    getInvestmentByStatus: builder.query<any, any>({
      query: ({ pageNumber, pageSize, byStatus }) => {
        const query = new URLSearchParams({
          page_number: pageNumber,
          page_size: pageSize,
          ...(byStatus && {
            by_status: byStatus
          })
        });
        return urlPrepare(`/investments?${query}`);
      },
      providesTags: ["Investments"]
    }),
    createInvestment: builder.mutation<any, any>({
      query: (body) => ({
        url: urlPrepare(`/investments`),
        method: "POST",
        body
      }),
      invalidatesTags: ["Investments"]
    }),
    getInvestmentById: builder.query<any, any>({
      query: (id) => ({
        url: urlPrepare(`/investments/${id}`),
        method: "GET"
      }),
      providesTags: ["Investments"]
    }),
    getInvestmentNav: builder.query<any, any>({
      query: (id) => ({
        url: urlPrepare(`/investments/${id}/nav`),
        method: "GET",
        providesTags: ["Investments"]
      })
    }),
    useGetInvestmentPerformance: builder.query<any, any>({
      query: (id) => ({
        url: urlPrepare(`/investments/${id}/performance`),
        method: "GET",
        providesTags: ["Investments"]
      })
    }),
    getInvestmentReturn: builder.query<any, any>({
      query: (id) => ({
        url: urlPrepare(`/investments/${id}/returns`),
        method: "GET",
        providesTags: ["Investments"]
      })
    }),
    createRebalancing: builder.mutation<any, any>({
      query: (investmentId) => ({
        url: urlPrepare(`/investments/${investmentId}/rebalance`),
        method: "POST",
        invalidatesTags: ["Investments"]
      })
    }),
    cancelInvestment: builder.mutation<any, any>({
      query: (investmentId) => ({
        url: urlPrepare(`/investments/${investmentId}/cancel`),
        method: "PUT",
        invalidatesTags: ["Investments"]
      })
    }),
    exitInvestment: builder.mutation<any, any>({
      query: (id) => ({
        url: urlPrepare(`/investments/${id}/exit`),
        method: "POST"
      }),
      transformErrorResponse: () => {
        return message.error("an error has occured");
      },
      transformResponse: () => {
        return Swal.fire({
          title: "Success",
          text: "Investment exited successfully",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1890ff"
        });
      },
      invalidatesTags: ["Investments"]
    }),
    partialExit: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: urlPrepare(`/investments/${id}/partial-exit`),
        method: "POST",
        body
      }),
      transformErrorResponse: () => {
        return message.error("an error has occured");
      },
      transformResponse: () => {
        return Swal.fire({
          title: "Success",
          text: "Investment partially exited successfully",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1890ff"
        });
      },
      invalidatesTags: ["Investments"]
    }),
    topUp: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: urlPrepare(`/investments/${id}/top-up`),
        method: "POST",
        body
      }),
      transformErrorResponse: () => {
        return message.error("an error has occured");
      },
      transformResponse: () => {
        return Swal.fire({
          title: "Success",
          text: "Investment topped up successfully",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1890ff"
        });
      },
      invalidatesTags: ["Investments"]
    }),
    getTransactions : builder.query({
      query: ({ client_id, page_number, page_size }) => {
        let queryParam = `/transactions?page_number=${page_number}&page_size=${page_size}`
        if (client_id && client_id !== 'ALL'){
          queryParam += `&client_id=${client_id}`;
        }
        return urlPrepare(queryParam);
      },
      providesTags: ["Transactions"]
    })
  })
});

export const {
  useGetInvestmentsQuery,
  useGetInvestmentByStatusQuery,
  useCreateInvestmentMutation,
  useGetInvestmentByIdQuery,
  useGetInvestmentNavQuery,
  useUseGetInvestmentPerformanceQuery,
  useGetInvestmentReturnQuery,
  useCreateRebalancingMutation,
  useCancelInvestmentMutation,
  useExitInvestmentMutation,
  usePartialExitMutation,
  useTopUpMutation,
  useGetInvestmentsByClientIdQuery,
  useGetTransactionsQuery
} = InvestmentsApi;
